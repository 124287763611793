import "./Alphabet.scss";
import React, {useEffect, useState} from "react";
import {OutsideAlerter} from "../../utils/OutsideAlerter";
import {data} from "./data";
import {ReactComponent as CloseBtn} from "./../../../assets/close-button.svg";
import {baseUrl} from "../../../App";
import {FetchSoundAndPlay, stopMusic} from "../../../utils/Music";

export const Alphabet = ({changeRegion, className}) => {
    const [isClosed, toggleClosed] = useState(false);
    const [agglomerations, setAgglomerations] = useState(null);

    useEffect(() => {
        getListAgglomerations()
        return () => {
        }
    }, []);

    useEffect(() => {
        if (!isClosed) {
            return () => stopMusic();
        }

        FetchSoundAndPlay("/map/getaudiofile/AudioListRegions");
        return () => stopMusic();
    }, [isClosed])

    const getListAgglomerations = async () => {
        const response = await fetch(`${baseUrl}/map/agglomerations`)
        const agglomerations = await response.json();
        setAgglomerations(agglomerations)
    }


    const changeRegionByDataId = (e) => {
        const target = e.target;
        if (target.getAttribute("data-id") == "donetsk" ||
        target.getAttribute("data-id") == "lugansk" ||
        target.getAttribute("data-id") == "zaporozhye"|| 
        target.getAttribute("data-id") == "kherson")
            return;
        if (target.hasAttribute("data-id")) {
            // changeRegion("salehard");
            changeRegion(target.getAttribute("data-id"));
            toggleClosed(false);
        }
    }
    const buildAlphabetItems = () => {
        return data.map((elem, index) => {
            return <div key={index} className="alphabet-open__item">
                <h3 className="alphabet-open__h3">{elem.title}</h3>
                {agglomerations.filter(({name}) => name.toLowerCase()[0] === elem.title.toLowerCase())
                    .map(({name, friendlyUrl}) => {
                        return <p key={friendlyUrl} onClick={changeRegionByDataId} data-id={friendlyUrl}>{name}</p>
                    })}
            </div>;
        })
    }

    return (
        <OutsideAlerter className={className} onOutside={() => toggleClosed(false)}>
            <div>
                <section className={"alphabet-open__rounded"}>
                    <div className={"alphabet " + (isClosed ? "alphabet_closed" : "")}
                         onClick={() => toggleClosed(!isClosed)}>
                        <div className="alphabet__all-regions">Список всех регионов</div>
                        <div className="alphabet__letters">
                            <div>А</div>
                            <div>Б</div>
                            <div>В</div>
                            <div>Г</div>
                            <div>...</div>
                        </div>
                    </div>
                </section>
                <div className={"alphabet-open " + (isClosed ? "alphabet-open_open" : "")}>
                    <section className={"alphabet-open__rounded"}>
                        <div className={"alphabet__wrapper"}>
                            <h2 className="alphabet-open__h2">алфавитный список всех регионов российской федерации</h2>
                            <div className="alphabet-open__items">
                                {agglomerations && buildAlphabetItems()}
                            </div>
                        </div>
                    </section>
                    <button className={"alphabet__close"} onClick={() => toggleClosed(!isClosed)}><CloseBtn/>
                    </button>
                </div>
            </div>
        </OutsideAlerter>
    );
}