import './Plan.scss';
import {memo} from "react";
import {ReactComponent as Calendar} from "../../../assets/plan/calendar.svg";
import {ReactComponent as Ruble} from "../../../assets/plan/ruble.svg";
import {ReactComponent as Cone} from "../../../assets/plan/cone.svg";
import {ReactComponent as Road} from "../../../assets/plan/road.svg";
import {ReactComponent as RoadWithBridge} from "../../../assets/plan/road_with_bridge.svg";
import {ReactComponent as Machine} from "../../../assets/plan/machine.svg";
import {ReactComponent as ConeWithRoad} from "../../../assets/plan/cone_with_road.svg";
import {ReactComponent as RoadBuild} from "../../../assets/plan/road_build.svg";
import {ReactComponent as MapPoints} from "../../../assets/plan/map_points.svg";
import {ReactComponent as Checked} from "../../../assets/plan/checked.svg";


export const Plan = memo(() => {
    return(
        <div className={"plan"}>
            <div className={"plan__title"}>
                ПЯТИЛЕТНИЙ ПЛАН ДОРОЖНОЙ ДЕЯТЕЛЬНОСТИ
            </div>
            <div className={"plan-wrapper"}>
                <div className={"plan__first"}>
                    <div className={"plan__first-wrapper"}>
                        <div className={"plan__first-title"}>
                            Утвержден Распоряжением Правительства РФ от 20 июня 2022 года №1601-р
                        </div>
                        <div className={"plan__first-cont-top"}>
                            <div className={"plan__first-definition"}>
                                <Calendar/>
                                <div className={"plan__first-definition-title"}>СРОКИ РЕАЛИЗАЦИИ:</div>
                                <div className={"plan__first-definition-description"}>2023 - 2027 гг.</div>
                            </div>
                            <div className={"plan__first-definition"}>
                                <Ruble/>
                                <div className={"plan__first-definition-title"}>ОБЪЕМ ФИНАНСИРОВАНИЯ:</div>
                                <div className={"plan__first-definition-description"}>13,2 трлн руб.</div>
                            </div>
                            <div className={"plan__first-definition"}>
                                <Cone/>
                                <div className={"plan__first-definition-title"}>КОЛИЧЕСТВО МЕРОПРИЯТИЙ:</div>
                                <div className={"plan__first-definition-description"}>более 250</div>
                            </div>
                        </div>
                        <div className={"plan__first-cont-bottom"}>
                            <div className={"plan__first-cont-bottom-wrapper"}>
                                <div className={"plan__first-cont-bottom-title"}>
                                    К КОНЦУ <span>2024</span> Г. В НОРМАТИВЕ:
                                </div>
                                <div className={"plan__first-cont-bottom-definition"}>
                                    <Road/>
                                    <div className={"plan__first-cont-bottom-definition-title"}>85% дорог в 105 агломерациях</div>
                                </div>
                                <div className={"plan__first-cont-bottom-definition"}>
                                    <RoadWithBridge classname={"plan__first-cont-bottom-definition-special"}/>
                                    <div className={"plan__first-cont-bottom-definition-title"}>50% региональных дорог</div>
                                </div>
                            </div>

                            <div className={"plan__first-cont-bottom-wrapper"}>
                                <div className={"plan__first-cont-bottom-title"}>
                                    К КОНЦУ <span>2027</span> Г. В НОРМАТИВЕ:
                                </div>
                                <div className={"plan__first-cont-bottom-definition"}>
                                    <Road/>
                                    <div className={"plan__first-cont-bottom-definition-title"}>85% опорной сети дорог</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"plan__second"}>
                    <div className={"plan__second-wrapper"}>
                        <div className={"plan__second-cont-top"}>
                            <div className={"plan__second-definition"}>
                                <MapPoints/>
                                <div className={"plan__second-definition-title"}>4 тыс. км</div>
                                <div className={"plan__second-definition-description"}> — протяженность объектов строительства и реконструкции </div>
                            </div>
                            <div className={"plan__second-definition"}>
                                <RoadBuild/>
                                <div className={"plan__second-definition-title"}>650 шт. <span>или</span> 125 тыс. пог. м</div>
                                <div className={"plan__second-definition-description"}> — строительство и реконструкция мостов и путепроводов </div>
                            </div>
                            <div className={"plan__second-definition"}>
                                <ConeWithRoad/>
                                <div className={"plan__second-definition-title"}>3 тыс. км</div>
                                <div className={"plan__second-definition-description"}> — дополнительное расширение дорог до 4 полос в рамках капитального ремонта </div>
                            </div>
                            <div className={"plan__second-definition"}>
                                <Machine/>
                                <div className={"plan__second-definition-title"}>110 тыс. км</div>
                                <div className={"plan__second-definition-description"}> — протяженность объектов ремонта дорог, </div>
                            </div>
                        </div>
                        <div className={"plan__second-cont-bottom"}>
                            <div className={"plan__second-cont-bottom-title"}>ЗАДАЧИ:</div>
                            <div className={"plan__second-cont-bottom-tasks"}>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>завершить</span>
                                     все начатые объекты
                                </div>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>построить</span>
                                     обходы городов
                                </div>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>расшить</span>
                                     узкие места на участках дорог, работающих в режиме перегрузки
                                </div>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>обеспечить</span>
                                     технологический и экономический суверенитет
                                </div>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>создать</span>
                                     спрос на металлопродукцию и иные отечественные строительные ресурсы
                                </div>
                                <div className={"plan__second-cont-bottom-task"}>
                                    <Checked/>
                                    <span>реализовать</span>
                                     объекты, предусмотренные поручениями<br/>
                                    Президента РФ (77 поручений), Правительства РФ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})