import "./WrapperModals.scss"
import React from 'react';
import {ReactComponent as Close} from "./../../../assets/close-button.svg";
import {OutsideAlerter} from "../OutsideAlerter";
import {stopMusic} from "../../../utils/Music";

export const WrapperModals = ({children, className, changeKey}) => {
    const handleClickOutside = (e) => {
        const buttons = [...document.querySelectorAll(".main__btn")];
        const res = buttons.reduce((a,b) => {
            if (typeof a !== "boolean")
                a = !a.contains(e.target);
            return a && !b.contains(e.target);
        })

        if (res) {
            changeKey(null);
        }
        stopMusic();
    }

    const visible = true;
    // const [visible, changeVisibility] = useState(true);
    return (
        <OutsideAlerter onOutside={handleClickOutside} className={className}>
            <section className={"modal-wrapper " + (visible ? "modal-wrapper_visible " : "modal-wrapper_hidden ") + (className ? className : "")}>
                <section className={"modal-wrapper__round"}>
                    <section className={"modal-wrapper__wrapper " }>
                        {children}
                    </section>
                </section>
                <button onClick={handleClickOutside} className={"modal-wrapper__close"}>
                    <Close/>
                </button>

                <svg style={{visibility: "hidden", position: "absolute"}} width="0" height="0"
                     xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo1">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur"/>
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                           result="goo"/>
                            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                        </filter>
                    </defs>
                </svg>
            </section>
        </OutsideAlerter>
    )

}