import "./Opornonet.scss";
import {ReactComponent as City} from "../../../assets/opornonet-def/city.svg";
import {ReactComponent as GrowUp} from "../../../assets/opornonet-def/growup.svg";
import {ReactComponent as TwoCars} from "../../../assets/opornonet-def/two-cars.svg";
import {memo} from "react";

export const Opornonet = memo(() => {
    return (
            <div className="opornonet-def">
                <div className={"opornonet-def-def__text-block"}>
                    <h2 className="opornonet-def__h2">Опорная сеть автомобильных дорог —</h2>
                    <p className="opornonet-def__p">это совокупность важнейших автомобильных дорог, обеспечивающих
                        устойчивые связи
                        между основными точками
                        генерации транспортного спроса (дорожный каркас транспортной системы Российской Федерации).</p>
                    <p className="opornonet-def__p">Включает все автомобильные дороги федерального значения, отдельные
                        региональные
                        и прочие автомобильные
                        дороги, отобранные на основе транспортно-географических критериев, показателей востребованности
                        и
                        социальной значимости, с учётом национальных приоритетов, стратегии пространственного развития
                        страны,
                        приоритетов развития иных видов транспорта.</p>
                </div>
                <div className={"opornonet-def-def__criteria"}>
                    <h2 className="opornonet-def__criteria-h2">критерии отбора:</h2>
                    <div className={"opornonet-def-def__criteria-content"}>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <City/>
                                <h3 className="opornonet-def__h3">Связанность территорий</h3>
                            </div>
                            <p>Связь между столицами субъектов, городами областного значения, обходы городов с
                                численностью
                                населения <span>свыше 100 тыс. человек</span></p>
                        </div>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <GrowUp/>
                                <h3 className="opornonet-def__h3">Социально-экономический рост</h3>
                            </div>
                            <p>Связанность центров экономического роста с объектами транспортной инфраструктуры и
                                МАПП</p>
                        </div>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <TwoCars/>
                                <h3 className="opornonet-def__h3">Востребованность</h3>
                            </div>
                            <p>Автомобильные дороги с интенсивностью движения
                                более <span>10 тыс. автомобилей в сутки</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    );
})